<template>
  <section>
    <ModalStatus :dialog="dialog" :type="type" @close="() => {
        dialog=false
        getTenantsAction()
      }"
      @emitChange="dispatchChangeStatus"  
    />
    <v-data-table
      :headers="tenant_tbl"
      :items="tenants"
      :items-per-page="5"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.status="{ item }">
        <v-select
          v-model="item.status"
          :items="status"
          item-value="value"
          dense
          flat
          solo
          :menu-props="{ top: true, offsetY: true }"
          small-chips
          class="f14 align-center"
          hide-details
          v-on:input="changeStatus($event, item.id)"
        >
          <template #selection="{ item }">
            <v-chip small label :color="item.text=='ACTIVE' ? 'primary' : ''">{{ item.text }}</v-chip>
          </template>
        </v-select>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn text class="text-capitalize primary--text f12 fw600" @click="$emit('edit', item.id)">
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
    </v-data-table>
    <!-- <v-simple-table class="mx-1 custom-border">
      <template v-slot:default>
        <thead>
          <tr style="">
            <th v-for="(item, i) in headers" :key="i" class="f12 poppins fw600 text-left text-uppercase secondary-2--text">
              {{item}}
            </th>
          </tr>
        </thead>
      
        <tbody >
          <tr v-for="(item, i) in tenants" :key="i" class="roboto f14 fw500 secondary-1--text">
            <td >{{item.tenant_name}}</td>
            <td>{{item.info.organization}}</td>
            <td>{{item.info.email}}</td>
            <td width="25%">
                <v-select
                  v-model="item.status"
                  :items="status"
                  item-value="value"
                  dense
                  flat
                  solo
                  :menu-props="{ top: true, offsetY: true }"
                  small-chips
                  class="f14 col-6 align-center"
                  hide-details
                  v-on:input="changeStatus($event, item.id)"
                >
                  <template #selection="{ item }">
                    <v-chip small label :color="item.text=='ACTIVE' ? 'primary' : ''">{{ item.text }}</v-chip>
                  </template>
                </v-select>
            </td >
            <td>{{item.domain_name}}</td>
            <td>
              <v-btn text class="text-capitalize primary--text f12 fw600" @click="$emit('edit', item.id)">
                <v-icon left>mdi-pencil</v-icon>
                Edit
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
  </section>
  
</template>

<script>
import ModalStatus from './ModalStatus.vue'
import { mapActions } from 'vuex';
import { tenant_tbl } from '@/constants/tblheaders/tenant'

export default {
  components: {
    ModalStatus
  },
  props: ['tenants'],
  data: () => ({
    tenant_tbl,
    headers: [
      'Name',
      'Organization',
      'Email',
      'Status',
      'Domain Name',
      'Action',
    ],
     status: [
      { text: "ACTIVE", value: "active" },
      { text: "INACTIVE", value: "inactive" },
    ],
    dialog: false,
    type: '',
    id: ''
  }),

  methods: {
    ...mapActions('superad', ['updateStatusTenantAction', 'getTenantsAction']),

    changeStatus(text, id) {
      this.dialog=true
      this.type = text
      this.id = id
       
    },

    dispatchChangeStatus() {
      this.updateStatusTenantAction({
        tenant_id: this.id,
        tenant_status: this.type.toLowerCase(),
        _method: 'PUT'
      }).then(() => {
        this.dialog=false
        this.$store.commit('alertMutation', {
          show: true,
          text: `Tenant Updated!`,
          type: "success"
        })
      })
    }
  }

}
</script>