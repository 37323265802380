<template>
  <section id="tenants">
    <Table :tenants="tenants" @view="view" v-if="!loading" @edit="edit"/>
    <circular v-else/>
    <ModalUpdateTenant :dialog="show" :form="tenant" @close="show=false" :type="type"/>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import SearchHeader from '../../components/super/tenant/SearchHeader.vue'
import Table from '../../components/super/tenant/Table.vue'
import ModalUpdateTenant from '../../components/super/tenant/ModalUpdateTenant.vue'

export default {
  components: {
    SearchHeader,
    Table,
    ModalUpdateTenant
  },
  data: () => ({
    tab: null,
    items: ['Tenants', 'Unpaid'],
    show: false,
    loading: true,
    type: 'view'
  }),
  computed: {
    ...mapState('superad', {
      tenants: (state) => state.tenants,
    }),
    ...mapState({
      tenant: (state) => state.show
    })
  },
  created() {
    this.getTenantsAction().then(() => {
      this.loading=false
    }).catch(() => {
      this.loading=false
      this.alertMutation({
        show: true,
        text: 'Something went wrong',
        type: "error"
      })
    })
  },
  methods: {
    ...mapActions('superad',[
      'getTenantsAction', 
      'showTenantAction'
    ]),

    ...mapMutations(['alertMutation']),

    view(id) {
      this.showTenantAction(id).then(() => {
        this.type='view'
        this.show=true
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    },

    edit(id) {
      this.showTenantAction(id).then(() => {
        this.type='edit'
        this.show=true
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    }
  }
}
</script>
